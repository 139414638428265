import { render, staticRenderFns } from "./servicesCatalog.vue?vue&type=template&id=3f6ea328&scoped=true&"
import script from "./servicesCatalog.vue?vue&type=script&lang=js&"
export * from "./servicesCatalog.vue?vue&type=script&lang=js&"
import style0 from "./servicesCatalog.vue?vue&type=style&index=0&id=3f6ea328&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f6ea328",
  null
  
)

export default component.exports