<template >
  <b-container fluid class="container" >
    <loading :active.sync="isWorking" :is-full-page="true"></loading>
    <b-row align="center" ><b-col><h4 style="color:white; margin-bottom:40px; margin-top:15px;"><b-icon icon="newspaper"/> Catálogo de Trámites y Servicios</h4></b-col></b-row>
    <b-row >
      <!-- <b-col xl="4" md="4" sm="6" cols="12" class="card-tramite">
        <center>
          <transition name="slide-fade">
            <b-card class="card-panel-catalog-user c-shadow-sm text-center" style="margin-top:40px;" v-if="isShowCardsUser">
                <b-row align="center" justify="center">
                  <div class="img-supposition-rounded">
                    <div :style="iconBall"> 
                      <img width="45px;" height="45px;" style="margin-top:25px;" src="/imgs/online-pay.png" />
                    </div>
                  </div>
                </b-row>
                <b-row style="min-height:60px;" class="row-title-card" align-h="center">
                  <span class="title-card">Pago Impuesto Predial</span>
                  <span class="title-card"></span>
                  <span class="title-card">y Facturación</span>
                </b-row>
                <h6 style="margin-top:10px; color:#F58028"><b-icon icon="file-earmark-text-fill"/> 2 Trámites</h6>
                <div class="footer-card-cat" :style="{background: themeColor}">
                  <b-button class="dropbtn" :style="{background: themeColor}" @click="showPortalContribuyente">Ver Trámites <b-icon icon="arrow-right-circle"/></b-button>     
                </div>   -->
                
                <!--<b-button pill block size="sm" variant="success" class="button-enter" @click="showPortalContribuyente">Entrar</b-button>-->
            <!-- </b-card>
          </transition>
        </center>
      </b-col> -->
      <b-col xl="4" md="4" sm="6" cols="12" class="card-tramite" v-for="categoria in categorias" :key="categoria.idCategoria">
        <center>
          <transition name="slide-fade">
            <b-card class="card-panel-catalog-user c-shadow-sm" style="margin-top:40px;" v-if="isShowCardsUser">
              <b-row align="center" justify="center">
                <div class="img-supposition-rounded">
                  <div :style="iconBall"> 
                    <img width="45px;" height="45px;" style="margin-top:25px;" :src="'../imgs/' + categoria.icon" />
                  </div>
                </div>
              </b-row>
              <b-row  style="min-height:60px;" class="row-title-card text-center" align-h="center" align-v="center">
                <b-col cols="12"><span class="title-card">{{categoria.nombre.toLowerCase()}}</span></b-col>
              </b-row>
              <h6 style="margin-top:10px; color:#F58028"><b-icon icon="file-earmark-text-fill"/> {{categoria.services.length}} Trámites</h6>
              <div class="footer-card-cat" :style="{background: themeColor}">
                <b-button class="dropbtn" :style="{background: themeColor}" @click="showTramitesCat(categoria)">Ver Trámites <b-icon icon="arrow-right-circle"/></b-button>     
              </div>         
            </b-card>
          </transition>
        </center>
      </b-col>
    </b-row>
    <!------------ MODALS -------------->
    <b-modal   v-model="isShowModalTramitesCat" hide-footer hide-header>
      <div style="margin-left:-16px; margin-right:-16px; margin-top:-17px;" class="container-head">
        <div class="bg-head-modal" :style="{ background:gradientColor }">
        </div>
      </div>
      <b-row align-h="center" style="margin-top:-200px;">
        <b-col align="left" ><img  height="50px;" :src="'/imgs/logosmpios/'+codeClient+'.png'" /></b-col>
        <b-col align="right"><b-button pill size="sm" variant="warning" @click="isShowModalTramitesCat=false"><b-icon icon="arrow-left-circle"/> Regresar</b-button></b-col>
      </b-row>
      <b-row align="center">
        <b-col>
          <br>
          <h5 v-if="categoriaActual.nombre!=null" style="color:white; text-transform:capitalize;">
            <img width="25px;" height="25px;" style="margin-top:-15px;" :src="'../imgs/' + categoriaActual.icon" />
            {{categoriaActual.nombre.toLowerCase()}}
          </h5>
        </b-col>
      </b-row><br>
      <div style="margin-left:0px; margin-right:0px; margin-bottom:20px; border-radius:20px; padding:10px;" class="c-shadow-sm" v-for="service in categoriaActual.services" :key="service.idServiceInfo">
        <div style="cursor:pointer;" @click="showServiceInfo(service.idServiceInfo)">
          <b-row align="center"><b-col cols="12"><h6 style="text-transform:capitalize;" :style="{color : iconsColor}">{{service.nombre.toLowerCase()}}</h6></b-col></b-row>
          <b-row align-h="center" align-v="center"> 
            <b-col cols="3" sm="2"><b-icon :style="{color: iconsColor}" font-scale="4" icon="file-earmark-text-fill"/></b-col>
            <b-col cols="8" sm="9">
              <b-row align="left">
                <b-col cols="12" sm="6"><b style="font-size:90%;"><b-icon variant="success" icon="cursor-fill"/> En Línea</b> </b-col>
                <b-col cols="12" sm="6"><b style="font-size:90%;"><b-icon variant="info" icon="building"/> Presencial</b></b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
    
  </b-container>
</template>

<script>
  import { mapState} from 'vuex';
  import { serviceInfo, tramitesInfoUser } from '@/axios-tools';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import Vue from 'vue';
  import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
  Vue.use(BootstrapVue)
  Vue.use(BootstrapVueIcons)

  export default {
    computed: {
      ...mapState({
        categorias: state => state.user.categorias,
        token: state => state.user.token,
        isWorking: state => state.user.isWorking,
        isShowCardsUser: state => state.user.isShowCardsUser,
        perfil: state => state.user.perfil,
        gradientColor : state => state.user.gradientColor,
        themeColor : state => state.user.principalColor,
        iconsColor : state => state.user.iconsColor
      }),
      codeClient:function(){
        return this.$route.params.municipio;
      },
      styleIconBall: function(){
        var style = this.iconBall;
        if(this.width <= 768){
          style=this.iconBallSm;
        }
        return style;
      },
      iconBall: function(){
        return " display: block; width: 100%; height: 100%; align-items: center; " +
        "background:" +this.gradientColor+";"+
        "border-radius: 50%; " +
        "box-shadow: inset -25px -25px 40px rgba(10,10,30,.4); " +
        "background-image: -webkit-linear-gradient(-45deg, rgba(255,255,220,.2) 0%, transparent 100%); " +
        "background-image: -moz-linear-gradient(-45deg, rgba(255,255,220,.2) 0%, transparent 100%); " +
        "background-image: -o-linear-gradient(-45deg, rgba(255,255,220,.2) 0%, transparent 100%); " +
        "background-image: -ms-linear-gradient(-45deg, rgba(255,255,220,.2) 0%, transparent 100%); " +
        "position:absolute; left: 0px; top:0px; text-align:center; ";
      }
    },
    components: {
      Loading
    },
    data() {
      return {
        test:false,
        width: window.innerWidth,
        categoriaActual:[],
        isShowModalTramitesCat:false,
      }
    },
    created: function () {
      if(this.categorias==null || this.categorias==[]){
        tramitesInfoUser(this.$route.params.municipio, this.$store);
      }else{
        var store = this.$store;
        setTimeout(function(){ store.commit("updateIsShowCardsUser", true); }, 300);
      }
    },
    methods: {
      showPortalContribuyente(){
        this.$router.push("/"+this.$route.params.municipio+"/portalcontribuyente");
        this.$store.commit("updateIsShowCardsUser", false);
      },
      showServiceInfo(idServiceInfo){/* Método que envia la petició al servidor para obtener la información de un trámite */
        this.isShowModalTramitesCat=false
        serviceInfo(idServiceInfo, this.$route.params.municipio, this.$store, this.$router);
        this.$store.commit("updateIsShowCardsUser", false);
      },
      showTramitesCat(categoria){
        this.categoriaActual=categoria
        this.isShowModalTramitesCat=true;
      }
    },
    validations: {
        
    }
  }
</script>
<style scoped>
.img-supposition-rounded{
  background: linear-gradient(90deg, rgba(28,81,141,1) 0%, rgba(0,57,120,1) 100%);
  box-shadow: 4px 4px 15px 1px #5f5f5f;
  border-radius: 50%;
  font-size:20px;
  padding:20px;
  width: 98px;
  height: 98px;
  position:absolute;
  top:-49px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}
.item-list-tramites{
  color:#000000; 
  font-weight: bold;
}
.margin-icon{
  margin-bottom:10px;
}
.button-enter{
  margin-top:19px;
}
.card-tramite{
  margin-bottom:20px;
}
.row-title-card{
  margin-top:50px;
  margin-left:5px; 
  margin-right:5px;
}
.title-card{
  font-size: 17px;
  font-weight: bold;
  text-transform: capitalize;
}
.title-icon{
  margin-right:10px;
}
.dropdown-catalog{
  min-width:83%;
  position: absolute; 
  bottom:25px;
}
.text-link{
  font-size:100%;
  margin-left:10px;
  margin-top:5px;
}
.text-iniciar{
  font-size:100%;
  margin-top:5px;
}
.card-panel-catalog-user{
  border-radius:15px;
  /*min-height:80px;*/
  max-width:250px;
}

/* Dropdown */
.dropbtn {
  /*background: linear-gradient(90deg, rgba(164,191,251,1) 0%, rgba(140,170,237,1) 10%, rgba(96,120,180,1) 60%);*/
  /* background-color: #6262E8; */
  width:100%;
  border-radius:15px;
  height: 33px;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  font-weight: bolder;
}

.dropdown {
  /*width:100%;
  position: relative;
  display: inline-block;*/
  min-width:83%;
  position: absolute; 
  bottom:20px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #f1f1f1}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #6262E8;
}

.button-tram-list{
  margin:-15px; background-color:white; color:black; border:white; text-align:left; font-weight:bold;
}

.footer-card-cat{
  /* background-color:#6262E8;  */
  height:50px; 
  margin-left:-20px; 
  margin-right:-21px;
  margin-top:40px;
  margin-bottom:-21px; 
  border-bottom-left-radius: 15px; 
  border-bottom-right-radius: 15px;
}


/* hexagono */
/*#inner{
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -moz-transform: rotate(45deg); 
  background-color:silver;
  width:100px;
  height:100px;
  top: 20px;
  left: -50px;
  position:relative;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

#outer {
  position: absolute;
  width: 70px;
  height: 140px;    
  top:20px;
  left:50px;
  overflow: hidden;
  transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg); 
}

#lower {
  top:99px;
  left:15px;
	width: 124px;
	height: 75px;
	background: silver;
	position: relative;
  border-radius: 20px;
}*/
</style>